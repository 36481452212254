import React, { memo } from 'react';
import Scripts from './Scripts.js';

/* import '../styles/richTextContent.css'; */

const RichText = memo(function RichTextContent({ className = "", html = "", runScripts = false }) {
    const template = document.createElement("template");
    template.innerHTML = html;

    /* Add comma style to not empty author lines */
    [...template.content.querySelectorAll(".quote-box")].forEach(quoteBox => {
        const author = quoteBox.querySelector(".quote-box--author");
        if(author && author.innerHTML.trim().length && !author.innerHTML.match(/^(&nbsp;)+$/g)) {
            author.classList.add("quote-box--author-comma");
        }
    });

    return (
        <>
            <div className={`rich-text ${className}`} dangerouslySetInnerHTML={{ __html: template.innerHTML }} />
            { runScripts && <Scripts template={template} /> }
        </>
    );
});

export default RichText;
