import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

const search = async ({ skip = 0, limit = 1, order = "asc", sort = null, match = "", logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/live/search/${match}?skip=${skip}&limit=${limit}&order=${order}` + (sort ? `&sort=${sort}` : ""), {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing site search results: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load site search results: ${response.statusText}`);
};

export default search;