import React from 'react';
import formatAssetUrl from '../helpers/formatAssetUrl.js';
import StandardDate from './StandardDate.js';
import RichText from './RighTextContent.js';
import Player from './Plugins/player/Player.js';

import '../styles/podcastPreview.css';

const PodcastContent = ({ data = {}, headingLevel = "h2" }) => {
    const { podcast = {}, autoPlay = true } = data;

    const podcastImage = (image) => {
        return formatAssetUrl({
            path: image
        });
    };
    podcast.formattedImage = podcastImage(podcast.image);

    return (
        <div className="podcast-preview">
            { podcast.source?.match(/^https?:\/\//) ? (
                <Player
                    playerOptions={{ muted: false, autoPlay }}
                    src={podcast.source}
                    config={{ solidColor: "white", image: podcast.formattedImage }}
                />
            ) : (
                <>
                    { podcast.formattedImage && (
                        <div className="podcast-preview--image-wrapper">
                            <div className="podcast-preview--image" style={{ backgroundImage: `url('${podcast.formattedImage}')` }}></div>
                        </div>
                    )}
                    <div className="podcast-preview--injected-player-wrapper" dangerouslySetInnerHTML={{ __html: podcast.source }} />
                </>
            ) }
            <div className="podcast-preview--content">
                <div className="podcast-preview--details">
                    <div className="podcast-preview--date">
                        <StandardDate date={new Date(podcast.publishDate)} inline={true}/>
                    </div>
                </div>
                { headingLevel === "h3" ? <h3>{podcast.title}</h3> : <h2>{podcast.title}</h2> }
                <RichText className="podcast-preview--description" html={podcast.description} />
            </div>
        </div>
    );
};

export default PodcastContent;