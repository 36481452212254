import React, { useState, useEffect, useContext, useRef } from 'react';
import GlobalContext from '../../contexts/global.js';
import useGlobal from '../../hooks/useGlobal.js';
import PodcastCollection from '../../components/PodcastCollection.js';
import HeroSection from '../../components/HeroSection.js';
import { changeBrowserTitle } from '../../hooks/headAmender.js';
import PodcastContent from '../../components/PodcastContent.js';
import RichText from '../../components/RighTextContent.js';
import Loader from '../../components/Loader.js';

import '../../styles/podcastsPage.css';

const Podcasts = ({ prefix }) => {
    const { globalContext } = useContext(GlobalContext);
    const { getCategoryOption } = useGlobal();
    const [switching, setSwitching] = useState(false);
    const [activePodcast, setActivePodcast] = useState({});
    const [podcastsConfig, setPodcastsConfig] = useState({});

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        (async () => {
            const data = await getCategoryOption({ category: "podcasts" });
            isMounted.current && data && setPodcastsConfig(data);
        })();
        return () => {
            isMounted.current = false;
        }
    }, []);

    /* Set browser title */
    changeBrowserTitle(globalContext?.siteConfig?.siteName || "Podcasts");

    return (
        <>
            <HeroSection
                heading={ podcastsConfig.heroTitle ?? "Podcasts" }
                backgroundColor={ podcastsConfig.heroStyle ?? "default" }
                textColor={ podcastsConfig.heroTextColor ?? "default" }
                image={ podcastsConfig.heroImage }
                preventPlaceholderImage={ true }
            />

            <section className="podcasts-page">
                <div className="container">
                    <div className="grid grid--md-50-50">
                        <h2 className="podcasts-page--title">{ Object.keys(activePodcast).length ? "Currently playing" : "Select a podcast from the list of podcasts below" }</h2>
                        <div className="podcasts-page--description-column">
                            <div className="podcasts-page--description">
                                <RichText className="story--body" html={ podcastsConfig.description } runScripts={false} />
                            </div>
                        </div>
                        <div className="podcasts-page--content-container">
                            <Loader running={switching} absolute={true} />
                            <div className="podcasts-page--content" style={{ opacity: switching ? 0 : 1 }}>
                                { Object.keys(activePodcast).length ? <PodcastContent data={activePodcast} headingLevel="h3" /> : null }
                            </div>
                            <PodcastCollection filterEnabled={true} openFirstOnLoad={true} onSelectionChanged={data => {
                                if(Object.keys(activePodcast).length) {
                                    isMounted.current && setSwitching(true);
                                    window.setTimeout(() => {
                                        if(isMounted.current) {
                                            setSwitching(false);
                                            setActivePodcast(data)
                                        }
                                    }, 500);
                                }
                                else {
                                    setActivePodcast(data)
                                }
                            }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Podcasts;