import React from 'react';
import { useState, useEffect } from 'react';

const Scripts = ({ template = [] }) => {
    const [processedScripts, setProcessedScripts] = useState([]);

    useEffect(() => {
        let scriptsToRun = [...template.content.querySelectorAll("script")].map(script => {
            const content = {
                loaded: false,
                src: script.src || null,
                code: script.innerHTML || null
            };
            script.remove();
            return content;
        });

        /* Load scripts */
        const loadScripts = scripts => {
            const scriptElements = [];
            scripts.map(({ src = "" }) => {
                const scriptElement = document.createElement("script");
                scriptElement.src = src;
                scriptElements.push(scriptElement);
            });
            return scriptElements;
        };

        /* Run scripts */
        const runScripts = scripts => {
            const scriptElements = [];
            scripts.map(({ code = "" }) => {
                const scriptElement = document.createElement("script");
                scriptElement.innerHTML = code;
                scriptElements.push(scriptElement);
            });
            return scriptElements;
        };

        setProcessedScripts([
            ...runScripts(scriptsToRun.filter(script => script.code)), /* Local scripts */
            ...loadScripts(scriptsToRun.filter(script => script.src)) /* External scripts */
        ]);
    }, [template]);

    useEffect(() => {
        processedScripts.forEach(processedScript => {
            const scriptSource = processedScript.src;
            if(scriptSource) {
                processedScript.addEventListener("load", event => console.log("Script loaded", scriptSource));
                processedScript.addEventListener("error", event => console.log("Script failed to load", scriptSource));
            }
            document.body.appendChild(processedScript);
        }); /* Add scripts on processedScripts change */
        return () => {
            processedScripts.forEach(processedScript => document.body.removeChild(processedScript)); /* Remove scripts on unmount */
        }
    }, [processedScripts]);

    return <div className="scripts" data-script-count={ processedScripts.length }></div>;
};

export default Scripts;
