import React from 'react';
import { ReactComponent as VolumeIcon } from './assets/volume.svg';
import { ReactComponent as MutedIcon } from './assets/muted.svg';

const Volume = ({ muted = false, volume = 0, min = 0, max = 1, step = 0.01, onMuteChange = () => {}, onVolumeChange = () => {} }) => {
  const barPercentage = volume * 100;
  const barLinearGradent = muted ?
    `linear-gradient(90deg, #ddd ${barPercentage}%, #eee ${barPercentage}%)` :
    `linear-gradient(90deg, #00b3ff ${barPercentage}%, #dadee3 ${barPercentage}%)`;

  return (
    <div className="themed-player--volume">
      { muted ? (
        <button type="button" className="themed-player--button themed-player--unmute" onClick={ () => onMuteChange(false) }>
          <MutedIcon/>
          <div className="themed-player--button-tooltip">Unmute</div>
        </button>
      ) : (
        <button type="button" className="themed-player--button themed-player--mute" onClick={ () => onMuteChange(true) }>
          <VolumeIcon/>
          <div className="themed-player--button-tooltip">Mute</div>
        </button>
      )}
      <div className="themed-player--range-wrapper">
        <input
            type="range"
            value={volume}
            step={step}
            min={min}
            max={max}
            className="themed-player--volume-bar"
            onChange={ event => onVolumeChange(event.target.value) }
            style={{ background: barLinearGradent }}
        />
        <div className="themed-player--button-tooltip">{ `${Math.ceil(volume * 100)}%` }</div>
      </div>
    </div>
  );
};

export default Volume;
