import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

const pageDetails = async ({ logout = logoutPlaceholder, pageUrns }) => {
    if(Array.isArray(pageUrns) && pageUrns.length) {
        try {
            const response = await fetch(`${apiEndPoint}/api/live/filterAndExpand`, {
                headers: {
                    "Content-Type": "application/json"
                },
                mode: "cors",
                credentials: "include",
                method: "POST",
                body: JSON.stringify({
                    urns: pageUrns
                })
            });

            if(response.ok) {
                const data = await response.json();
                return data.pages ?? [];
            }
            response.status === 401 && logout(); 
        }
        catch(error) {
            throw new Error(`Failed lading page details: ${error}`);
        }
    }
    return [];
};

export default pageDetails;
