import React, { useState, useEffect } from 'react';
import useGlobal from '../hooks/useGlobal.js';

import '../styles/siteQuote.css';

const SiteQuote = () => {
    const { getCategoryOption } = useGlobal();

    const [content, setContent] = useState({});

    useEffect(() => {
        let current = true;
        (async () => {
            const data = await getCategoryOption({ category: "siteConfig" });
            current && data && setContent(data);
        })();
        return () =>  current = false;
    }, []);

    if(content.quoteLabel && content.quoteText) {
        return (
            <div className="site-quote">
                <div className="site-quote--title">{content.quoteLabel ?? ""}</div>
                <div className="site-quote--content">
                    <blockquote className="site-quote--text">{content.quoteText ?? ""}</blockquote>
                    <div className="site-quote--author">{content.quoteAuthor ?? ""}</div>
                </div>
            </div>
        );
    }
    return null;
};

export default SiteQuote;