
const createMetaTag = ({ name, content, existingMeta }) => {
    const meta = existingMeta || document.createElement("meta");
    meta.name = name;
    meta.content = content;
    return meta;
};

export const fillPageHeadMeta = (data) => {
    const head = document.querySelector("head");
    if(head) {
        Object.entries(data).forEach(([ metaName, content ]) => {
            const existingMeta = head.querySelector(`meta[name="${metaName}"]`);
            head.appendChild(createMetaTag({ name: metaName, content, existingMeta }));
        });
    }
};

export const changeBrowserTitle = (content) => {
    const title = document.querySelector("head title");
    if(title) {
        title.innerText = content;
    }
};
