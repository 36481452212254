import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import AccessContext, { getLoginOptions } from './contexts/access.js';
import UserContext, { getSessionCookie } from './contexts/user.js';
import GlobalContext, { setDefaults } from './contexts/global.js';
import StateContext, { StateProvider } from './contexts/state.js';
import RequireAuth from './components/RequireAuth.js';
import ScrollToTop from './hooks/scrollToTop.js';

import SiteConfig from './components/SiteConfig.js';
import UserConfig from './components/UserConfig.js';

import Login from './pages/Login.js';
import Search from './pages/Search.js';
import User from './pages/User.js';
import Story from './templates/pages/Story.js';
import Contact from './templates/pages/Contact.js';
import Home from './templates/pages/Home.js';
import Podcast from './templates/pages/Podcast.js';
import Podcasts from './templates/pages/Podcasts.js';
import Topic from './templates/pages/Topic.js';
import Header from './components/Header.js';
import PageLayout from './components/PageLayout.js';
import Footer from './components/Footer.js';

import './styles/main.css';

const App = () => {
  const [accessContext, setAccessContext] = useState({});
  const [userContext, setUserContext] = useState(getSessionCookie());
  const [globalContext, setGlobalContext] = useState(setDefaults());
  const [stateContext, setStateContext] = useState({});

  useEffect(() => {
    let isMounted = true;
    (async () => {
        const response = await getLoginOptions();
        if(isMounted) {
          setAccessContext(response);
        }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <AccessContext.Provider value={{ accessContext, setAccessContext }}>
      <UserContext.Provider value={{ userContext, setUserContext }}>
        <GlobalContext.Provider value={{ globalContext, setGlobalContext }}>
          <StateProvider value={{ stateContext, setStateContext }}>
            <Router >
              <SiteConfig />
              <UserConfig />

              <Header/>

              <PageLayout footer={<Footer />}>
                <ScrollToTop>
                  <Routes>
                    <Route path="/login" element={
                      <Login/>
                    } />
                    <Route path="/search/:match?" element={
                      <RequireAuth>
                        <Search/>
                      </RequireAuth>
                    } />
                    <Route path="/story/*" element={
                      <RequireAuth>
                        <Story prefix="/story"/>
                      </RequireAuth>
                    } />
                    <Route path="/topic/:categoryValue" element={
                      <RequireAuth>
                        <Topic prefix="/topic"/>
                      </RequireAuth>
                    } />
                    <Route path="/contact/*" element={
                      <RequireAuth>
                        <Contact prefix="/contact"/>
                      </RequireAuth>
                    } />
                    <Route path="/user/*" element={
                      <RequireAuth>
                        <User/>
                      </RequireAuth>
                    } />
                    <Route path="/preview/page/*" element={
                      <RequireAuth>
                        <Story prefix="/preview/page" preview={true} />
                      </RequireAuth>
                    } />
                    <Route path="/preview/contact/*" element={
                      <RequireAuth>
                        <Contact prefix="/preview/contact" preview={true} />
                      </RequireAuth>
                    } />
                    <Route path="/podcasts" element={
                      <RequireAuth>
                        <Podcasts prefix="/podcasts" />
                      </RequireAuth>
                    } />
                    <Route path="/podcast/*" element={
                      <RequireAuth>
                        <Podcast prefix="/podcast" />
                      </RequireAuth>
                    } />
                    <Route path="/preview/podcast/*" element={
                      <RequireAuth>
                        <Podcast prefix="/preview/podcast" preview={true} />
                      </RequireAuth>
                    } />
                    <Route path="/" element={
                      <RequireAuth>
                        <Home prefix="/" />
                      </RequireAuth>
                    } />
                  </Routes>
                </ScrollToTop>
              </PageLayout>
            </Router>
          </StateProvider>
        </GlobalContext.Provider>
      </UserContext.Provider>
    </AccessContext.Provider>
  );
};

export default App;