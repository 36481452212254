import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import useUser from '../hooks/useUser.js';
import { getTopicConfig } from '../helpers/api/topicConfig.js';
import pageDetails from '../helpers/api/pageDetails.js';
import StandardDate from './StandardDate.js';
import Loader from './Loader.js';
import formatAssetUrl from '../helpers/formatAssetUrl.js';
import VideoPlayer from './VideoPlayer.js';

import '../styles/featuredContent.css';
import '../styles/grid.css';

const knownFeatureTypes = {
    basic: {
        name: "basic",
        count: 5,
        initial: true
    },
    default: {
        name: "5boxes",
        count: 5,
        firstLarge: true,
        colorMap: [ null, "yellow", null, null, "pink" ]
    },
    video5: {
        name: "video5",
        count: 5,
        firstLarge: true,
        colorMap: [ null, "yellow", null, null, "pink" ]
    },
    featured7: {
        name: "7boxes",
        count: 7,
        wrap: 4,
        columnSpan: 2,
        colorMap: [ "grey", null, "yellow", null, null, null, "pink" ]
    },
    featured8: {
        name: "8boxes",
        count: 8,
        wrap: 4,
        colorMap: [ null, "red", "yellow", null, null, null, null, "pink" ]
    }
};

const matchType = (type) => {
    if(knownFeatureTypes[type]) {
        return knownFeatureTypes[type];
    }
    return Object.entries(knownFeatureTypes).find(([type, data]) => data.initial)?.pop() || {};
};

const getColorMap = (colorStyle, colorMap, index) => {
    if(colorStyle === "plainColor") {
        return colorMap ? (colorMap[index] || "white") : "white";
    }
    if(colorStyle === "somePlainColor") {
        return colorMap ? (colorMap[index] || null) : null;
    }
    return null;
};

const FeaturedItem = ({ page, index, options = {}, video = {} }) => {
    let itemImage = null;
    if(!options.baseColor) {
        itemImage = formatAssetUrl({
            path: page.data?.image,
            fallback: `${process.env.PUBLIC_URL}/assets/images/related-placeholder.jpg`
        });
    }
    const CardWrapper = ({ children }) => {
        const classes = `
            featured--item featured--item-${options.baseColor ? "color" : "image"}
            ${video.src ? "featured--item-video" : ""}
            featured--item-${options.baseColor ?? "white"}
            ${options.lastVistDate < new Date(page.publishDate).getTime() ? "featured--new" : ""}
        `.trim().replace(/\s+/, " ");

        if(video.src) {
            return <div className={classes}>{children}</div>;
        }
        return <Link className={classes} to={`/story/${page.urn}`}>{children}</Link>
    };

    const CardInfo = ({ children }) => (
        <>
            <div>
                <span className="featured--detail featured--date"><StandardDate date={new Date(page.publishDate)} inline={true}/></span>
                <h5 className="featured--detail featured--title">{page.data.title}</h5>
                { options.showAbstract && page.data.abstract && <p className="featured--detail featured--abstract">{page.data.abstract}</p> }
            </div>
            <>
                { children }
            </>
        </>
    );

    const showVideo = options.name.match(/video/) && video.src;
    return (
        <CardWrapper>
            {
                (itemImage && !showVideo) && (
                    <div className="featured--bg-container">
                        <div className="featured--bg" style={{ backgroundImage: `url('${itemImage}')` }} />
                    </div>
                )
            }
            {
                showVideo && (
                    <div className="featured--bg-container">
                        <div className="featured--video">
                            <VideoPlayer src={formatAssetUrl({
                                path: video.src,
                                fallback: `${process.env.PUBLIC_URL}/assets/videos/boxes.mp4`
                            })} loop={ video.loop ?? true } />
                        </div>
                    </div>
                )
            }

            { showVideo ? (
                <div className="featured--info">
                    <CardInfo>
                        <Link className="featured--detail read-more" to={`/story/${page.urn}`}>
                            Read the story behind this video
                        </Link>
                    </CardInfo>
                </div>
            ) : (
                <div className="featured--info">
                    <CardInfo />
                </div>
            )}
        </CardWrapper>
    );
};

const FeaturedContent = ({ heading = "", category = "all", type = "basic", videoSrc = "", colorStyle = "", onPagesChanged = () => {} }) => {
    const { logout } = useUser();

    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const [options, setOptions] = useState(null);
    const [lastVistDate, setLastVisitDate] = useState(Date.now()); // number of milliseconds elapsed since January 1, 1970
    const isMounted = useRef(false);
    
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
      }, []);

    useEffect(() => setOptions(() => matchType(type || "basic")), [type]);
    useEffect(() => onPagesChanged(pages), [pages]);

    useEffect(() => {
        if(category && options) {
            setLoading(true);
            (async () => {
                try {
                    const { options } = await getTopicConfig({ logout, category }) ?? {};
                    if(isMounted.current) {
                        if(options?.featured) {
                            try {
                                const pageUrns = JSON.parse(options.featured);
                                setPages(pageUrns.length ? await pageDetails({ logout, pageUrns }) : []);
                            }
                            catch(error) {
                                console.error(error);
                            }
                        }
                        else {
                            setPages([]);
                        }
                    }
                }
                catch (error) {
                    console.error(error);
                }
                if(isMounted.current) {
                    setLoading(false);
                }
            })();
        }
    }, [category, options]);

    useEffect(() => {
        const lastVisitCookieName = `lastVist${category}${options?.name || ""}`;

        const lastVist = parseInt(Cookies.get(lastVisitCookieName) || 0);
        setLastVisitDate(lastVist);

        const expires = new Date();
        expires.setTime(expires.getTime() + (5 * 24 * 60 * 60 * 1000));
        Cookies.set(lastVisitCookieName, Date.now(), { expires, path: "" });
    }, []);
    
    if(loading && pages.length === 0) {
        return <Loader running={loading}/>;
    }
    if(!options || !pages.length) {
        return null;
    }

    const listBoxes = (pages, reportedOffset = 0) => {
        return pages.map((page, index) => {
            const Item = ({ advanced = {} }) => <FeaturedItem key={page.urn} page={page} index={index + reportedOffset} options={{
                ...options,
                ...advanced
            }} />;

            if(index === options.columnSpan - 1) {
                return (
                    <div className="column double-row featured--double-row" key={page.urn} style={{
                        gridRow: "3/1",
                        gridColumn: "2/2"
                    }}>
                        <Item advanced={{ lastVistDate, showAbstract: true, baseColor: getColorMap(colorStyle, options.colorMap, index + reportedOffset) }} />
                    </div>
                );
            }
            return (
                <div className="column" key={page.urn}>
                    <Item advanced={{ lastVistDate, showAbstract: !(index + reportedOffset) && options.firstLarge, baseColor: getColorMap(colorStyle, options.colorMap, index + reportedOffset) }} />
                </div>
            );
        });
    }

    return (
        <div className="featured">
            { heading && <h2 className="featured--heading">{heading}</h2> }
            { 
                options.firstLarge ? (
                    <div className="columns sys-2">
                        <div className="featured--items featured--large">
                            <FeaturedItem key={pages[0].urn} page={pages[0]} index={0} options={{
                                ...options,
                                lastVistDate,
                                showAbstract: true,
                                baseColor: getColorMap(colorStyle, options.colorMap, 0)
                            }} video={{
                                ...(options.name === "video5" && {
                                    src: videoSrc,
                                    loop: videoSrc ? false: true
                                })
                            }} />
                        </div>
                        <div className="columns sys-50-50 featured--items">
                            { listBoxes(pages.slice(1), 1) }
                        </div>
                    </div>
                ) : (
                    <div className={`featured--items columns ${options.wrap ? `sys-${options.wrap}` : "sys-50-50"} v-stretch`}>
                        { listBoxes(pages) }
                    </div>
                )
            }
        </div>
    );
};

export default FeaturedContent;