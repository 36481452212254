import React, { useState, useEffect } from 'react';
import useUser from '../../../hooks/useUser.js';
import StandardDate from '../../StandardDate.js';
import { verifyInstagramFeed, getInstagramFeed } from '../../../helpers/api/instagramFeed.js';

import './instagramFeed.css';

const InstagramFeed = ({ count }) => {
    const { logout } = useUser();
    const [account, setAccount] = useState({});
    const [posts, setPosts] = useState([]);
    
    useEffect(() => {
        let isMounted = true;
        const loadFeed = async () => {
            try {
                const verification = await verifyInstagramFeed({ logout });
                if(verification.hasAccessToken) {
                    const data = await getInstagramFeed({ count, logout });
                    if(isMounted) {
                        data.account && setAccount(data.account);
                        data.posts && setPosts(data.posts);
                    }
                }
            }
            catch (error) {
                /* console.error(error); */
                /* Instagram feed failed loading, but fade away silently because it doesn't really matter here ... */
            }
        };
        loadFeed();
        return () => isMounted = false;
    }, []);

    if(!posts.length) {
        return null;
    }

    return (
        <div className="instagram-feed">
            <h2 className="instagram-feed--heading">
                Instagram <a href={`https://www.instagram.com/${account.username}`} target="_blank" rel="noreferrer">@{account.username}</a>
            </h2>
            <div className="instagram-feed--posts instagram-feed--posts-scrollable" tabIndex={-1}>
                {
                    posts.map(({ id, mediaType, mediaUrl, thumbnailUrl, caption, timestamp, username, permalink }, index) => (
                        <div key={index} className="instagram-feed--post">
                            <div className="instagram-feed--post-info">
                                <a href={`https://www.instagram.com/${username}`} target="_blank" rel="noreferrer" className="instagram-feed--post-info-source">
                                    <img alt="PwC" src={`${process.env.PUBLIC_URL}/assets/logos/pwclogo.svg`}/>
                                    {username}
                                </a>
                                <div className="instagram-feed--post-info-timestamp">
                                    <StandardDate date={new Date(timestamp)} inline={true}/>
                                </div>
                            </div>
                            <a href={permalink} target="_blank" rel="noreferrer" className="instagram-feed--media">
                                { mediaType === "image" && <img alt={caption} src={mediaUrl} /> }
                                { mediaType !== "image" && (
                                    <video controls poster={thumbnailUrl}>
                                        <source src={mediaUrl} type={mediaType}/>
                                        <img alt={caption} src={thumbnailUrl} />
                                    </video>
                                )}
                            </a>
                            <div className="instagram-feed--caption">
                                <pre>{caption}</pre>
                            </div>
                        </div>
                    ))
                }
            </div>
            <a className="instagram-feed--view-on-instagram" href={`https://www.instagram.com/${account.username}`} target="_blank" rel="noreferrer">View on Instagram</a>
        </div>
    );
};

export default InstagramFeed;