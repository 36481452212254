import React from 'react';

const PageNotFound = () => {
    return (
        <section>
            <div className="container">
                <h1>Page not found</h1>
                <p style={{ marginTop: "40px" }}>We are sorry but the content for the requested page could not be loaded. The URL for this page is no longer valid.</p>
                <p>You can use the site search function to try to find the content you are interested in.</p>
            </div>
        </section>
    );
};

export default PageNotFound;