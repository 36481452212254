import React, { createContext } from 'react';
import { getExtraPageFields } from '../helpers/api/getExtraPageFields.js';
import { getSiteConfig } from '../helpers/api/siteConfig.js'

const GlobalContext = createContext();
export default GlobalContext;

export const setDefaults = () => {
    return {
        siteConfig: {
            siteName: "@PwC",
            quote: {
                label: "",
                text: "",
                author: ""
            },
            featuredBanner: {
                link: "",
                text: ""
            },
            configOptions: []
        },
        categories: [],
        additionalMenuOptions: []
    };
};

export const getConfig = async ({ logout }) => {
    try {
        const siteConfig = await getSiteConfig({ logout });
        const extraPageFields = await getExtraPageFields({ logout });
        const mainOptions = extraPageFields.properties?.find(category => category.mainCategory) || {};

        return {
            siteConfig,
            extraPageFields,
            categories: [
                { key: "all", value: "All content", ignoreFilter: true },
                ...mainOptions.items
            ],
            additionalMenuOptions: [
                { key: "podcasts", value: "Podcasts", url: "/podcasts", icon: `${process.env.PUBLIC_URL}/assets/icons/categories/podcasts.svg`, smaller: true }
            ],
            date: new Date()
        };
    }
    catch (error) {
        console.error(`Error getting site config: ${error}`);
        return null;
    }
};
