import React, { useEffect, useContext } from 'react';
import useUser from '../hooks/useUser.js';
import UserContext from '../contexts/user.js';
import GlobalContext, { getConfig } from "../contexts/global.js";

const SiteConfig = () => {
    const { userContext } = useContext(UserContext);
    const { setGlobalContext } = useContext(GlobalContext);
    const { logout } = useUser();

    useEffect(() => {
        let isMounted = true;
        const loadConfig = async () => {
            const config = await getConfig({ logout });
            if(isMounted && config) {
                setGlobalContext(config);
            }
            else {
                console.error("Failed getting site config.");
            }
        };
        userContext.authenticated && loadConfig();
        return () =>  isMounted = false;
    }, [userContext.authenticated]);

    return null;
};

export default SiteConfig;
