import React from 'react';
import { Link } from 'react-router-dom';
import StandardDate from './StandardDate.js';
import formatAssetUrl from '../helpers/formatAssetUrl.js';

import '../styles/relatedContent.css';

const RelatedContentItem = ({ contentItem }) => {
    const pageData = contentItem.data || contentItem.page || null;

    const itemImage = formatAssetUrl({
        path: pageData?.image,
        fallback: `${process.env.PUBLIC_URL}/assets/images/related-placeholder.jpg`
    });

    return (
        <Link className="related-content--item" to={`/story/${contentItem.urn}`}>
            <div className="related-content--image-wrapper">
                <div className="related-content--image" style={{ backgroundImage: `url('${itemImage}')` }}></div>
            </div>
            <div className="related-content--details">
                <StandardDate date={contentItem.publishDate ? new Date(contentItem.publishDate) : null} className="related-content--date" inline={true}/>
                <div className="related-content--title">{pageData?.title || contentItem.urn}</div>
            </div>
        </Link>
    );
};

const RelatedContent = ({ heading = null, content = [], padding = true }) => {
    if(!content.length) {
        return null;
    }

    return (
        <div className={`related-content ${padding ? "" : "related-content--no-padding"}`}>
            { heading && <h3 className="related-content--heading">{heading}</h3> }
            <div className="related-content--row">
                { content.map(contentItem => <RelatedContentItem key={contentItem.urn} contentItem={contentItem} />) }
            </div>
        </div>
    );
};

export default RelatedContent;