import React, { useContext } from 'react';
import GlobalContext from '../contexts/global.js';
import useUser from '../hooks/useUser.js';
import { getSiteConfig } from './../helpers/api/siteConfig.js';

const useGlobal = () => {
    const { globalContext, setGlobalContext } = useContext(GlobalContext);
    const { logout } = useUser();

    const getCategoryOption = async ({ category, key }) => {
        const data = await getSiteConfig({ logout, category }) ?? {};
        return (key ? data?.[key] : data) ?? null;
    };

    return {
        getCategoryOption
    };
};

export default useGlobal;