import React from 'react';
import '../styles/standardDate.css';

const StandardDate = ({ date = new Date(), className = "standard-date", classNamePrefix = "", inline = false }) => {
    const parts = {
        day: date ? date.toLocaleString("en-US", { day: "2-digit" }) : "DD",
        month: date ? date.toLocaleString("en-US", { month: "short" }) : "month",
        year: date ? date.toLocaleString("en-US", { year: "numeric" }) : "year"
    };
    
    return (
        <div className={className}>
            {
                inline ?
                Object.entries(parts).map(([key, value], index, sourceArray) => `${value}${index < sourceArray.length ? " " : ""}`) :
                Object.entries(parts).map(([key, value]) => <div key={key} className={`${classNamePrefix}${key}`}>{value}</div>)
            }
        </div>
    );
};

export default StandardDate;