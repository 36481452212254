import React from 'react';
import { ReactComponent as PlayIcon } from './assets/play.svg';
import { ReactComponent as PauseIcon } from './assets/pause.svg';

const PlayPause = ({ isPlaying = false, setIsPlaying = () => {} }) => (
  <>
    { isPlaying ? (
      <button type="button" className="themed-player--button themed-player--play" onClick={ () => setIsPlaying(false) }>
        <PauseIcon/>
        <div className="themed-player--button-tooltip">Pause</div>
      </button>
    ) : (
      <button type="button" className="themed-player--button themed-player--pause" onClick={ () => setIsPlaying(true) }>
        <PlayIcon/>
        <div className="themed-player--button-tooltip">Play</div>
      </button>
    )}
  </>
);

export default PlayPause;
