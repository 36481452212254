import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from '../hooks/useUser.js';
import AccessContext from '../contexts/access.js';
import UserContext from '../contexts/user.js';
import auth from '../helpers/api/auth.js';
import { changeBrowserTitle } from '../hooks/headAmender.js';
import { getLoginEntryPoint, preferredLoginMethodPath, useLocalLogin } from '../hooks/userAccess.js';

import '../styles/login.css';

const LoginPage = () => {
    const { accessContext } = useContext(AccessContext);
    const { userContext, setUserContext } = useContext(UserContext);
    const { login, logout, availableLoginOptions, preferredLoginOption } = useUser(accessContext);
    const isMounted = useRef(false);
    
    const location = useLocation();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const submitForm = async (event) => {
        event.preventDefault();
        
        const response = await auth({
            login,
            email,
            password
        });

        if(isMounted.current) {
            setUserContext(response);
            if(response.error) {
                alert(response.error);
            }
            else {
                navigate(location.state?.referer || "/");
            }
        }
    };

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    changeBrowserTitle("Login");

    /* Display log-out button in case user is already logged-in */
    if(userContext.authenticated) {
        return (
            <div className="login-page">
                <div className="container">
                    <div className="login-page--btn" onClick={logout}>Log out</div>
                </div>
            </div>
        );
    }

    /* Show basic log-in form in development mode */
    if(preferredLoginOption.direct?.preferred || (availableLoginOptions()?.entry?.direct && useLocalLogin(location))) {
        /* Show regular log-in form */
        return (
            <div className="login-page">
                <div className="container">
                      <form className="login-form" method="post" onSubmit={submitForm}>
                        <div className="login-form--group">
                            <label htmlFor="email">Email:</label>
                            <input type="text" name="email" required value={email} onChange={(event) => setEmail(event.currentTarget.value)} />
                        </div>
                        <div className="login-form--group">
                            <label htmlFor="password">Password:</label>
                            <input type="password" name="password" required value={password} onChange={(event) => setPassword(event.currentTarget.value)} />
                        </div>
                        <div className="login-form--submit">
                            <input type="submit" value="Log In"/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    /* Show login button link otherwise */
    if(!preferredLoginOption.direct && Object.keys(preferredLoginOption).length) {
        return (
            <div className="login-page">
                <div className="container">
                    <div className="login-page--btn" onClick={() => {
                        window.location.href = getLoginEntryPoint({
                            loginPath: preferredLoginMethodPath(preferredLoginOption),
                            location
                        });
                    }}>Log In</div>
                </div>
            </div>
        );
    }

    return null;
};

export default LoginPage;
