import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../hooks/useUser.js';
import { getCount, listInfo } from '../helpers/api/getPage.js';
import search from '../helpers/api/search.js';
import StandardDate from './StandardDate.js';
import Loader from './Loader.js';
import formatAssetUrl from '../helpers/formatAssetUrl.js';

import '../styles/collection.css';

const Collection = ({
    heading = "",
    paginateAfter = 3,
    pagination = true,
    filter = { type: "category", value: null, sort: null, order: "desc" },
    showCount = false,
    fullWidth = false,
    customTools = null,
    toolsAlignment = null,
    trimDescriptionTo = 280
}) => {
    const { logout } = useUser();
    const isMounted = useRef(false);

    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [unavailableItems, setUnavailableItems] = useState(0);
    const [pages, setPages] = useState([]);
    const [limit, setLimit] = useState(paginateAfter);
    const [order, setOrder] = useState(filter.order || "desc");

    const loadMore = async ({ reset = false }) => {
        setLoading(true);
        try {
            const response = filter.type === "match" ?
            await search({ skip: reset ? 0 : pages.length, limit, order, match: filter.value, sort: filter.sort, logout }) :
            await listInfo({ skip: reset ? 0 : pages.length, limit, order, categoryKey: filter.type, categoryValue: filter.value, logout });

            if(isMounted.current) {
                if(response && response.pages) {
                    (response.count || filter.type === "match") && setCount(response.count || 0);
                    response.unavailableItems && setUnavailableItems(unavailableItems + response.unavailableItems);
                    setPages([
                        ...( !reset ? pages : [] ),
                        ...response.pages
                    ]);
                }
                else {
                    if(reset) {
                        setPages([]);
                    }
                }
            }
        }
        catch (error) {
            console.error(error);
        }
        isMounted.current && setLoading(false);
    };

    useEffect(() => {
        /* const loadCountInfo = async () => {
            try {
                const categoryCount = await getCount({ categoryKey: filter.type, categoryValue: filter.value, logout });
                isMounted.current && setCount(categoryCount?.count || 0);
            }
            catch(error) {
                console.error(error);
            }
        };
        filter.type === "category" && loadCountInfo(); */
        loadMore({ reset: true });
    }, [filter.type, filter.value, filter.sort]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);

    const CollectionItem = ({ page = {} }) => {
        const itemImage = formatAssetUrl({
            path: page.data?.image,
            fallback: `${process.env.PUBLIC_URL}/assets/images/related-placeholder.jpg`
        });

        return (
            <Link className="collection--card" to={`/story/${page.urn}`}>
                <div className="collection--image-wrapper">
                    <div className="collection--image" style={{ backgroundImage: `url('${itemImage}')` }}></div>
                </div>
                <div className="collection--details">
                    <div className="collection--date-wrapper">
                        <StandardDate date={new Date(page.publishDate)} />
                    </div>
                    <div className="collection--info">
                        <div className="collection--title">{ page.data?.title || "" }</div>
                        <div className="collection--text">
                            { (trimDescriptionTo && page.data?.abstract?.length > trimDescriptionTo) ?
                                `${page.data.abstract.substring(0, trimDescriptionTo).replace(/[\s,-_|]+[^\s]*$/, "")}...` :
                                page.data?.abstract
                            }
                        </div>
                    </div>
                </div>
            </Link>
        );
    };

    const filteredCount = count - unavailableItems;
    
    if(!loading && pages.length === 0) {
        return <span>No content to display</span>;
    }
    return (
        <div className={`collection ${fullWidth ? "collection--fullWidth" : ""}`}>
            { heading ? <h2 className="collection--heading">{heading}</h2> : null }
            { showCount ? <span className="collection--count">{filteredCount} { filteredCount !== 1 ? "items" : "item" }</span> : null }
            <div className="collection--cards">
                { pages.map(page => <CollectionItem key={page.urn} page={page}/>) }
            </div>
            <Loader running={loading}/>
            <div className={`collection--tools ${toolsAlignment ? `collection--tools-align-${toolsAlignment}` : ""}`}>
            { pagination ? pages.length < filteredCount && !loading && <div className="collection--load-more" onClick={loadMore}>Load more</div> : null }
            { customTools }
            </div>
        </div>
    );
};

export default Collection;