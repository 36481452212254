import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../hooks/useUser.js';
import { getSnippetByUrn } from '../helpers/api/getSnippet.js';
import Loader from './Loader.js';
import formatAssetUrl from '../helpers/formatAssetUrl.js';

import '../styles/featuredContact.css';

const FeaturedContact = ({ title = "", quote = "", contact = null }) => {
    const { logout } = useUser();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        (async () => {
            try {
                const contactData = await getSnippetByUrn({ type: "contact", urn: contact, logout });
                if(isMounted && contactData) {
                    setData(contactData);
                }
            }
            catch (error) {
                console.error(error);
            }
            if(isMounted) {
                setLoading(false);
            }
        })();
        return () => {
            isMounted = false;
        }
    }, [contact]);
    
    if(loading) {
        return <Loader running={loading}/>;
    }

    if(!Object.keys(data).length) {
        return quote ? (
            <div className="featured-contact featured-contact--full-width">
                <div className="featured-contact--content">
                    <blockquote className="featured-contact--quote">{quote.trim()}</blockquote>
                </div>
            </div>
        ) : null;
    }

    const fullName = `${data.name} ${data.surname}`;
    return (
        <div className="featured-contact">
            <div className="featured-contact--image-wrapper">
                <div className="featured-contact--image" style={{
                    backgroundImage: `url('${formatAssetUrl({
                        path: data.image,
                        fallback: `${process.env.PUBLIC_URL}/assets/images/hero-placeholder.jpg`
                    })}')`
                }} title={fullName} />
            </div>
            <div className="featured-contact--content">
                <div>
                    <div className="featured-contact--name">{fullName}</div>
                    <div className="featured-contact--job-title">{data.jobTitle}</div>
                </div>

                { quote && <blockquote className="featured-contact--quote">{quote.trim()}</blockquote> }

                <Link className="featured-contact--read-more" to={`/contact/${data.urn}`}>Read {data.name}&rsquo;s profile</Link>
            </div>
            { title && <h2 className="featured-contact--heading">{title}</h2> }
        </div>
    );
};

export default FeaturedContact;