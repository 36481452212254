import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

export const getPageContentByUrn = async ({ urn, logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/live/page/${urn}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            const parsed = await response.json();
            if(parsed.page) {
                return parsed;
            }
            return null;
        }
        catch (error) {
            throw new Error(`Failed lading page content: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load page content: ${response.statusText}`);
};

export const getPageContentByIndex = async ({ id, logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/live/index/${id}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            const parsed = await response.json();
            if(parsed.page) {
                return parsed;
            }
            return null;
        }
        catch (error) {
            throw new Error(`Failed lading page content: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load page content: ${response.statusText}`);
};

export const getPagePreviewContentByIndex = async ({ id }) => {
    const response = await fetch(`${apiEndPoint}/api/author/page/content/${id}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            const parsed = await response.json();
            if(parsed.page) {
                return parsed;
            }
            return null;
        }
        catch (error) {
            throw new Error(`Failed lading page content: ${error}`);
        }
    }
    throw new Error(`Could not load page content: ${response.statusText}`);
};

export const getCount = async ({ categoryKey = null, categoryValue = null, logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/live/${(categoryKey && categoryValue) ? `categoryCount?categoryKey=${categoryKey}&categoryValue=${categoryValue}` : "count"}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing the list of pages with details: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load the list of pages with details: ${response.statusText}`);
};

export const listInfo = async ({ skip = 0, limit = 1, order = "asc", sort = null, categoryKey = null, categoryValue = null, logout = logoutPlaceholder }) => {
    const commonQueries = `?skip=${skip}&limit=${limit}&order=${order}` + (sort ? `&sort=${sort}` : "");
    const path = (categoryKey && categoryValue) ? `/api/live/listCategoryInfo${commonQueries}&categoryKey=${categoryKey}&categoryValue=${categoryValue}` : `/api/live/listInfo${commonQueries}`;
    const response = await fetch(`${apiEndPoint}${path}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing the list of pages with details: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load the list of pages with details: ${response.statusText}`);
};
