import React, { useState, useEffect, useRef } from 'react';
import useUser from '../hooks/useUser.js';
import { getCount, list } from '../helpers/api/getSnippet.js';
import Loader from './Loader.js';

import '../styles/podcasts.css';

const PodcastItem = ({ podcast, active = false, onClick = () => {} }) => {
    const time = podcast.time || "-- : --";
    const withLeadingZero = time.replace(/^(\d):/, "0$1:");
    return (
        <div className={`podcasts--item ${active ? "podcasts--item-active" : ""}`} to={`/story/${podcast.urn}`} onClick={onClick}>
            <div className="podcasts--icon">
                <img src={`${process.env.PUBLIC_URL}/assets/icons/media/${active ? "play" : "podcast"}.svg`} alt="podcast"/>
            </div>
            <h3 className="podcasts--title">{podcast.title}</h3>
            <span className="podcasts--time">{withLeadingZero}</span>
        </div>
    );
};

const PodcastCollection = ({
    heading = "",
    paginateAfter = 6,
    pagination = true,
    initialOrder = "desc",
    openFirstOnLoad = false,
    filterEnabled = false,
    defaultSearchKeys = [ "title", "description" ],
    defaultSearch = "",
    onSelectionChanged = () => {}
}) => {
    const { logout } = useUser();
    const isMounted = useRef(false);

    const [loading, setLoading] = useState(true);
    const [podcasts, setPodcasts] = useState([]);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(paginateAfter);
    const [order, setOrder] = useState(initialOrder);
    const [searchKeys, setSearchKeys] = useState(defaultSearchKeys);
    const [search, setSearch] = useState(defaultSearch);
    const [activeItem, setActiveItem] = useState(null);

    const loadMore = async ({ reset = false }) => {
        setLoading(true);
        try {
            const response = await list({ type: "podcast", skip: reset ? 0 : podcasts.length, limit, order, searchKeys, search, logout });
            if(isMounted.current) {
                if(response && response.snippets) {
                    setCount(response.count || 0);
                    setPodcasts([
                        ...( !reset ? podcasts : [] ),
                        ...response.snippets
                    ]);

                    if(reset && openFirstOnLoad && response.snippets.length) {
                        setActiveItem(0);
                        onSelectionChanged({ podcast: response.snippets[0], autoPlay: false });
                    }
                }
                else {
                    if(reset) {
                        setPodcasts([]);
                    }
                }
            }
        }
        catch (error) {
            console.error(error);
        }
        isMounted.current && setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => loadMore({ reset: true }), 500);
        return () => clearTimeout(timer);
    }, [searchKeys, search]);

    useEffect(() => {
        isMounted.current = true;
        loadMore({ reset: true });
        return () => {
            isMounted.current = false;
        }
    }, []);
    
    if(!loading && !filterEnabled && podcasts.length === 0) {
        return <span>No content to display</span>;
    }

    return (
        <div className="podcasts">
            { heading ? <h2 className="podcasts--heading">{heading}</h2> : null }
            { filterEnabled && (
                <div className="podcasts--filter">
                    <span>Filter by content:</span>
                    <input type="text" placeholder="search in content" onChange={(event) => setSearch(event.currentTarget.value)}/>
                </div>
            )}
            <div className="podcasts--list">
                { podcasts.map((podcast, index) => (
                    <PodcastItem key={index + podcast.urn} podcast={podcast} active={activeItem === index} onClick={() => {
                        if(activeItem === index) {
                            if(!openFirstOnLoad) { /* Disable deselecting item if openFirstOnLoad is true */
                                setActiveItem(null);
                                onSelectionChanged({});
                            }
                        }
                        else {
                            setActiveItem(index);
                            onSelectionChanged({ podcast, autoPlay: true });
                        }
                    }}/>
                )) }
            </div>
            <Loader running={loading}/>
            <div className="podcasts--tools">
                { (!loading && pagination && podcasts.length < count) && <div className="podcasts--button" onClick={loadMore}>Load more</div> }
            </div>
        </div>
    );
};

export default PodcastCollection;