import React from 'react';
import { useParams } from 'react-router-dom';
import Collection from '../components/Collection.js';
import { changeBrowserTitle } from '../hooks/headAmender.js';

const SearchPage = () => {
    const { match } = useParams();
    changeBrowserTitle(`Search ${match ?? "all"}`);

    return (
        <section>
            <div className="container">
                <h1>Search results</h1>
                <Collection
                    filter={{
                        type: "match",
                        value: match,
                        sort: "date",
                        order: "desc"
                    }}
                    showCount={true}
                    paginateAfter={6}
                    pagination={true}
                    fullWidth={true}
                /> 
            </div>
        </section>
    );
};

export default SearchPage;
