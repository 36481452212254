import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser.js';
import { getSnippetByUrn } from '../../helpers/api/getSnippet.js';
import { getSnippetPreviewContentByIndex } from '../../helpers/api/getSnippet.js';
import StandardDate from '../../components/StandardDate.js';
import { changeBrowserTitle } from '../../hooks/headAmender.js';
import formatAssetUrl from '../../helpers/formatAssetUrl.js';
import PageNotFound from './PageNotFound.js';
import RichText from '../../components/RighTextContent.js';

import '../../styles/contact.css';

const Contact = ({ prefix, preview = false }) => {
    const { logout } = useUser();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [pageFailedLoading, setPageFailedLoading] = useState(false);
    const [content, setContent] = useState({});
    const [parsedBio, setParsedBio] = useState("");
    const [date, setDate] = useState(new Date());
    
    const apiEndPoint = process.env.REACT_APP_FETCH_URL;

    useEffect(() => {
        let isMounted = true;
        (async () => {
            const urn = location.pathname.replace(new RegExp(`^${prefix}/?`), "");
            try {
                const response = preview ? await getSnippetPreviewContentByIndex({ id: urn }) : await getSnippetByUrn({ type: "contact", urn, logout });
                if(isMounted) {
                    if(response) {
                        setContent(response);

                        if(apiEndPoint) {
                            setParsedBio(response.bio.replace(/(["'])\/api\//g, `$1${apiEndPoint}/api/`));
                        }

                        const pageDateString = response.publishDate || response.updated || response.created ;
                        if(pageDateString) {
                            setDate(new Date(pageDateString));
                        }
                    }
                    setLoading(false);
                    setPageFailedLoading(false);
                }
            }
            catch (error) {
                isMounted && setPageFailedLoading(true);
            }
        })();
        return () => {
            isMounted = false;
        }
    }, [location]);

    if(loading) {
        if(pageFailedLoading) {
            return <PageNotFound/>;
        }
        return <span>Loading content ...</span>;
    }

    /* Set browser title */
    changeBrowserTitle(`${content.name} ${content.surname}`);

    const userImage = formatAssetUrl({
        path: content?.image,
        fallback: `${process.env.PUBLIC_URL}/assets/images/hero-placeholder.jpg`
    });

    return (
        <>
            { preview && (
                 <section className="contact">
                    <div className="container">
                        <p className="contact--preview-notice">
                            <strong>You are currently in preview mode.</strong>
                        </p>
                    </div>
                </section>
            ) }

            <section className="contact">
                <div className="container">
                    <div className="contact--hero">
                        <div className="contact--hero-content">
                            <div>
                                <h1 className="contact--heading">{`${content.name} ${content.surname}`}</h1>
                                <StandardDate date={date} inline={true}/>

                                <dl className="contact--details">
                                    { content.jobTitle && (
                                        <>
                                            <dt>Job title</dt>
                                            <dd>{content.jobTitle}</dd>
                                        </>
                                    )}

                                    { content.email && (
                                        <>
                                            <dt>Email</dt>
                                            <dd><a href={`mailto:${content.email}`}>{content.email}</a></dd>
                                        </>
                                    )}

                                    { content.phoneNumber && (
                                        <>
                                            <dt>Phone number</dt>
                                            <dd><a href={`tel:${content.phoneNumber}`}>{content.phoneNumber}</a></dd>
                                        </>
                                    )}
                                </dl>
                            </div>

                            <div>
                                { content.socialShare?.length ? <h2 className="contact--social-links-title">Social links</h2> : "" }
                                <div className="contact--social-links">
                                    {
                                        (content.socialShare || []).map((socialShare, index) => (
                                            <a
                                                key={index}
                                                href={socialShare.url}
                                                title={socialShare.url.replace(/^https?:\/\/(www\.)?([^/]*).*$/, "$2")}
                                                target="_blank"
                                                rel="noreferrer"
                                                className={socialShare.type}
                                            >
                                                <img src={`${process.env.PUBLIC_URL}/assets/icons/social/${socialShare.type}.svg`} alt={socialShare.type} />
                                            </a>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="contact--profile-image-wrapper">
                            <img className="contact--profile-image" src={`${userImage}`} alt={`${content.name} ${content.surname}`} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact">
                <div className="container">
                    <div className="contact--content">
                        <RichText className="contact--body" html={ parsedBio || content.bio } />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;