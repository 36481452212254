import React, { createContext } from "react";

const AccessContext = createContext();
export default AccessContext;

export const getLoginOptions = async () => {
    const response = await fetch(`${process.env.REACT_APP_FETCH_URL}/api/login/methods`, {
        mode: "cors",
        credentials: "omit",
        method: "GET"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            console.error(`Failed parsing login options: ${error}`);
            return {};
        }
    }
    console.error(`Failed getting login options: ${response.statusText}`);
    return {};
};
