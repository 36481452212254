import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import GlobalContext from '../contexts/global.js';
import StateContext from "../contexts/state.js";

import '../styles/navigationSideBar.css';

let hoverTimeout = null;

const Item = ({ propKey: key, text, icon, url, smaller = false, setHoverOption = () => {} }) => {
    const { state, setState } = useContext(StateContext);
    const location = useLocation();

    const isCurrent = location.pathname.startsWith(url) || state.currentCategories?.find(category => category.trim().toLowerCase() === url.replace(/^.*\//, "").trim().toLowerCase());
    
    const focus = (event) => {
        const boudingClientRect = event.currentTarget.getBoundingClientRect();
        setHoverOption({ key, y: boudingClientRect.top, height: boudingClientRect.height });
    };
    const blur = () => setHoverOption(null);

    return (
        <Link
            to={url}
            className={`next-to-sidebar--link ${ isCurrent ? "next-to-sidebar--link-current" : "" }`}
            onMouseEnter={focus}
            onMouseLeave={blur}
            onFocus={focus}
            onBlur={blur}
            onClick={() => setState({ currentCategories: [] })}
        >
            <div className="next-to-sidebar--icon-wrapper">
                <img className="next-to-sidebar--icon" src={icon} alt={text}/>
            </div>
            <div className={`next-to-sidebar--text ${smaller ? "next-to-sidebar--text-smaller" : ""}`}>{text}</div>
        </Link>
    );
};

const NavigationSideBar = ({ items = [], headerHeight = null, onUpdate = () => {} }) => {
    const { globalContext } = useContext(GlobalContext);

    const [options, setOptions] = useState([]);
    const [additionalMenuOptions, setAdditionalMenuOptions] = useState([]);
    const [hoverOption, setHoverOption] = useState(null);

    useEffect(() => {
        setOptions(globalContext?.categories || []);
    }, [globalContext?.categories]);

    useEffect(() => {
        setAdditionalMenuOptions(globalContext?.additionalMenuOptions || []);
    }, [globalContext?.additionalMenuOptions]);

    useEffect(() => {
        window.clearTimeout(hoverTimeout);
        hoverTimeout = window.setTimeout(() => setHoverOption(null), 5000);
    }, [hoverOption]);

    useEffect(() => {
        onUpdate();
    }, [items, options, additionalMenuOptions]);

    const itemListData = [ ...items, ...options, ...additionalMenuOptions ];

    return (
        <div className="navigation-side-bar">
            <div className="next-to-sidebar--items-wrapper" style={{ ...(headerHeight && { top: `${headerHeight}px` }) }}>
                <div className="next-to-sidebar--items">
                    { itemListData.map(({ key, value, icon, url, smaller }) =>
                        <Item
                            key={ key }
                            propKey={ key }
                            text={ value }
                            icon={ icon || `${process.env.PUBLIC_URL}/assets/icons/categories/${key}.svg`}
                            url={ url || `/topic/${key}` }
                            smaller={ smaller }
                            setHoverOption={ setHoverOption }
                        />
                    ) }
                </div>
            </div>
            { hoverOption && (
                <div className="next-to-sidebar--tooltip" style={{ top: `${hoverOption.y + (hoverOption.height / 2)}px` }}>
                    { itemListData.find(({ key }) => hoverOption.key === key)?.value }
                </div>
            )}
        </div>
    );
};

export default NavigationSideBar;