import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import useUser from '../../hooks/useUser.js';
import GlobalContext from "../../contexts/global.js";
import StateContext from "../../contexts/state.js";
import { getPageContentByUrn, getPagePreviewContentByIndex } from '../../helpers/api/getPage.js';
import RelatedContent from '../../components/RelatedContent.js';
import StandardDate from '../../components/StandardDate.js';
import { changeBrowserTitle } from '../../hooks/headAmender.js';
import formatAssetUrl from '../../helpers/formatAssetUrl.js';
import PageNotFound from './PageNotFound.js';
import RichText from '../../components/RighTextContent.js';

import '../../styles/story.css';

const Story = ({ prefix, preview = false }) => {
    const { globalContext } = useContext(GlobalContext);
    const { state, updateState } = useContext(StateContext);
    const { logout } = useUser();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [pageFailedLoading, setPageFailedLoading] = useState(false);
    const [content, setContent] = useState({});
    const [contentBodyParsed, setContentBodyParsed] = useState("");
    const [date, setDate] = useState(new Date());

    const apiEndPoint = process.env.REACT_APP_FETCH_URL;

    useEffect(() => {
        let isMounted = true;
        (async () => {
            const urn = location.pathname.replace(new RegExp(`^${prefix}/?`), "");
            try {
                const response = preview ? await getPagePreviewContentByIndex({ id: urn }) : await getPageContentByUrn({ urn, logout });
                if(!isMounted) {
                    return false;
                }
                if(response) {
                    setContent(response.page);
                    // Update all api calls in the page body (asset references for instance) to go to the correct api location
                    if(apiEndPoint) {
                        setContentBodyParsed(response.page?.content.replace(/(["'])\/api\//g, `$1${apiEndPoint}/api/`));
                    }
                    const pageDateString = response.publishDate || response.page?.updated || response.page?.created;
                    if(pageDateString) {
                        setDate(new Date(pageDateString));
                    }
                    
                    /* Define current categories */
                    updateState({
                        currentCategories: response.page?.category
                    });
                }
                setLoading(false);
                setPageFailedLoading(false);
            }
            catch (error) {
                isMounted && setPageFailedLoading(true);
            }
        })();
        return () => {
            updateState({
                currentCategories: [] /* Unset categories on story page exit */
            });
            isMounted = false;
        }
    }, [location]);

    if(loading) {
        if(pageFailedLoading) {
            return <PageNotFound/>;
        }
        return <span>Loading content ...</span>;
    }

    /* Set browser title */
    content?.title && changeBrowserTitle(`${content.title}${globalContext?.siteConfig?.siteName ? ` - ${globalContext.siteConfig.siteName}` : ""}`);

    const storyImage = formatAssetUrl({
        path: content?.image,
        fallback: `${process.env.PUBLIC_URL}/assets/images/hero-placeholder.jpg`
    });

    return (
        <>
            { preview && (
                 <section className="story">
                    <div className="container">
                        <p className="story--preview-notice">
                            <strong>You are currently in a preview mode.</strong><br/>
                            Please note page date corresponds to the page version last update and is not the same as a real publish date.<br/>
                            Related content doesn&apos;t filter for live pages only and is not showing page titles, publish dates nor cover images in preview.
                        </p>
                    </div>
                </section>
            ) }
        
            <section className="section--no-padding story">
                <div className="story--hero-title">
                    <div className="container">
                        <div className="story--hero-content">
                            {
                                content.category?.map(category => {
                                    const categoryData = globalContext?.categories.find(availableCategory => availableCategory.key === category) || {};
                                    return <Link key={category} className="story--category" to={`/topic/${category}`}>{categoryData.value}</Link>
                                })
                            }
                            <h1 className="story--heading">{content.title}</h1>
                            <StandardDate date={date} inline={true} className="story--date"/>
                        </div>

                        <div className="story--hero-image" style={{ backgroundImage: `url("${storyImage}")` }} />
                    </div>
                </div>

                <div className="story--content">
                    <div className="container">
                        <RichText className="story--body" html={contentBodyParsed || content.content} runScripts={true} />
                    </div>
                </div>

                { content.related && (
                    <div className="story--related-articles">
                        <div className="container">
                            <RelatedContent heading="Related articles" content={ content.related } />
                        </div>
                    </div>
                ) }
            </section>
        </>
    );
};

export default Story;