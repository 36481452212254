import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

export const getExtraPageFields = async ({ logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/live/extraPageFields`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing the list of extra page fields: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load the list of available extra page fields: ${response.statusText}`);
};
