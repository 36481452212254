import React, { useState, createContext } from 'react';

const StateContext = createContext();

const defaultState = {
    currentCategories: []
};

export const StateProvider = ({ children, settings }) => {
    const [state, setState] = useState({
        ...defaultState,
        ...settings /* Keep defaults, but allow overwriting */
    });

    return (
        <StateContext.Provider value={{
            state,
            setState,
            updateState: properties => setState({ ...state, ...properties })
        }}>
            {children}
        </StateContext.Provider>
    );
};

export const StateConsumer = StateContext.Consumer;

export default StateContext;
