const auth = async ({ login, email, password }) => {
    try {
        const response = await login({ email, password });
        if(response.authenticated) {
            return response;
        }
        return {
            authenticated: response.authenticated,
            error: "User is not authenticated"
        };
    }
    catch(error) {
        return {
            authenticated: false,
            error
        };
    }
};

export default auth;