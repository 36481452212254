import React, { useState, useEffect, useContext, useRef } from 'react';
import AccessContext, { getLoginOptions } from '../contexts/access.js';
import UserContext from '../contexts/user.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

const useUser = () => {
    const { accessContext, setAccessContext } = useContext(AccessContext);
    const { setUserContext } = useContext(UserContext);
    const isMounted = useRef(false);

    const [requestBuffer, setRequestBuffer] = useState({});
    const [preferredLoginOption, setPreferredLoginOption] = useState({});

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        if(accessContext.entry) {
            const [key, value] = Object.entries(accessContext.entry || {}).find(([ key, { preferred } ]) => preferred) || [ "unknown", null ];
            setPreferredLoginOption({ [ key ]: value });
        }

        if(Object.keys(accessContext).length) {
            if(requestBuffer.login) {
                return login(requestBuffer.login);
            }
            if(requestBuffer.logout) {
                return logout();
            }
        }
    }, [accessContext]);

    const login = async (data) => {
        if(!accessContext.entry?.direct) {
            setRequestBuffer({ login: data });
            return console.error("Direct login is not enabled on this instance.");
        }
        const response = await fetch(`${apiEndPoint}${accessContext.entry.direct.login}`, {
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(data)
        });

        if(isMounted.current) {
            if(response.ok) {
                try {
                    return await response.json();
                }
                catch (error) {
                    throw new Error(`Login failed: ${error}`);
                }
            }
            throw new Error(`Login failed: ${response.statusText}`);
        }
    };

    const logout = async () => {
        if(!accessContext.logout) {
            setRequestBuffer({ logout: true });
            return console.error("Unable to log you out. No logout method provided");
        }
        const response = await fetch(`${apiEndPoint}${accessContext.logout}`, {
            mode: "cors",
            credentials: "include",
            method: "POST",
        });

        if(isMounted.current) {
            if(response.ok) {
                try {
                    setUserContext({
                        authenticated: false,
                        stayLocal: true
                    });
                    return true;
                }
                catch (error) {
                    throw new Error(`Failed loging user out: ${error}`);
                }
            }
            throw new Error(`Log out failed: ${response.statusText}`);
        }
    };

    return {
        login,
        logout,
        availableLoginOptions: () => accessContext,
        preferredLoginOption
    };
};

export default useUser;