import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

export const getSiteConfig = async ({ logout = logoutPlaceholder, category }) => {
    const response = await fetch(`${apiEndPoint}/api/live/siteConfig${category ? `/${category}` : ""}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing site config: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load site config: ${response.statusText}`);
};