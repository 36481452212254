import React, { useRef, forwardRef } from 'react';
import '../styles/videoPlayer.css';

const toggleVideoPlay = (videoElement) => {
    if(videoElement) {
        if(videoElement.paused) {
            videoElement.play();
        }
        else {
            videoElement.pause();
            exitFullscreen();
        }
        return videoElement;
    }
    return false;
};

const toggleVideoMute = (videoElement) => {
    if(videoElement) {
        videoElement.muted = !videoElement.muted;
        return true;
    }
    return false;
};

const exitFullscreen = () => {
    document.fullscreenElement && document.exitFullscreen();
};

let fsExitCallback = null;
const onFullscreenExit = () => {
    document.addEventListener("fullscreenchange", () => {
        [fsExitCallback].filter(callback => typeof callback === "function").forEach(callback => callback());
    });
};
onFullscreenExit();

const VideoPlayer = forwardRef(function VideoPlayer({ src, onStateChange = () => {}, loop = false }, videoPlayerRef) {
    return (
        <video
            ref={videoPlayerRef}
            autoPlay={false}
            muted={true}
            loop={loop}
            controls={false}
            onPlay={(event) => onStateChange(event)}
            onPause={(event) => onStateChange(event)}
            onEnded={(event) => onStateChange(event)}
            onVolumeChange={(event) => onStateChange(event)}
            onClick={ () => toggleVideoPlay(videoPlayerRef?.current) }
        >
            <source
                src={src}
                type={`video/${src.match(/\.mp4/) ? "mp4" : (src.match(/\.webm/) ? "webm" : (src.match(/\.avi/) ? "avi" : "ogv"))}`}
            />
        </video>
    );
});

const VideoPlayerWrapper = ({ src = null, loop = false }) => {
    const videoPlayerContainer = useRef(null);
    const videoPlayerRef = useRef(null);
    const videoPlayerPlayIconRef = useRef(null);
    const videoPlayerSoundIconRef = useRef(null);
    const videoPlayerFullscreenIconRef = useRef(null);

    if(!src) {
        return null;
    }
    return (
        <div className="video-player" ref={videoPlayerContainer}>
            <VideoPlayer src={src} onStateChange={(event) => {
                const paused = event.currentTarget.paused;
                if(videoPlayerPlayIconRef?.current) {
                    videoPlayerPlayIconRef.current.src = `${process.env.PUBLIC_URL}/assets/icons/media/${paused ? "play" : "pause"}.svg`;
                }
                const muted = event.currentTarget.muted;
                if(videoPlayerSoundIconRef?.current) {
                    videoPlayerSoundIconRef.current.src = `${process.env.PUBLIC_URL}/assets/icons/media/${muted ? "mute" : "sound"}.svg`;
                }
                if(videoPlayerContainer?.current) {
                    videoPlayerContainer.current.classList.remove("paused", "playing", "muted", "loud");
                    videoPlayerContainer.current.classList.add(paused ? "paused" : "playing");
                    videoPlayerContainer.current.classList.add(muted ? "muted" : "loud");
                }
            }} loop={loop} ref={videoPlayerRef} />

            <div className="button playStop" onClick={
                (event) => {
                    event.stopPropagation();
                    toggleVideoPlay(videoPlayerRef?.current);
                }
             }>
                <img
                    ref={videoPlayerPlayIconRef}
                    src={`${process.env.PUBLIC_URL}/assets/icons/media/play.svg`}
                    alt="playstop"
                />
            </div>
            <div className="button sound" onClick={
                (event) => {
                    event.stopPropagation();
                    toggleVideoMute(videoPlayerRef?.current);
                }
             }>
                <img
                    ref={videoPlayerSoundIconRef}
                    src={`${process.env.PUBLIC_URL}/assets/icons/media/sound.svg`}
                    alt="loudmute"
                />
            </div>
            <div className="button fullscreen" onClick={
                (event) => {
                    event.stopPropagation();

                    const updateFullScreenLayout = () => {
                        const inFs = !!document.fullscreenElement;
                        if(inFs) {
                            videoPlayerContainer?.current?.classList.add("fullscreen");
                        }
                        else {
                            videoPlayerContainer?.current?.classList.remove("fullscreen");
                        }

                        if(videoPlayerFullscreenIconRef.current) {
                            if(inFs) {
                                videoPlayerFullscreenIconRef.current.src = `${process.env.PUBLIC_URL}/assets/icons/media/exit-fullscreen.svg`;
                            }
                            else {
                                videoPlayerFullscreenIconRef.current.src = `${process.env.PUBLIC_URL}/assets/icons/media/fullscreen.svg`;
                            }
                        }
                    };

                    const toggleFullscreen = () => {
                        if(videoPlayerFullscreenIconRef.current) {
                            if(!videoPlayerContainer?.current?.classList.contains("fullscreen")) {
                                fsExitCallback = updateFullScreenLayout;
                                videoPlayerContainer.current.requestFullscreen();
                            }
                            else {
                                exitFullscreen();
                            }
                        }
                    };

                    toggleFullscreen();
                    updateFullScreenLayout();
                }
             }>
                <img
                    ref={videoPlayerFullscreenIconRef}
                    src={`${process.env.PUBLIC_URL}/assets/icons/media/fullscreen.svg`}
                    alt="toggleFullscreen"
                />
            </div>
        </div>
    );
};

export default VideoPlayerWrapper;