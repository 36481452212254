import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useWindowSize from '../hooks/windowSize.js';
import NavigationSideBar from './NavigationSideBar.js';

import '../styles/pageLayout.css';

const getHeaderHeight = () => {
    const siteHeader = document?.querySelector(".site-header");
    if(!siteHeader) {
        return 0;
    }
    return siteHeader.offsetHeight;
};

const getNavHeight = () => {
    const navigationSideBar = document?.querySelector(".navigation-side-bar");
    if(!navigationSideBar) {
        return 0;
    }
    return navigationSideBar.offsetHeight;
};

const PageLayout = ({ children = [], footer = null }) => {
    const location = useLocation();
    const size = useWindowSize();

    const [isMobile, setIsMobile] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [navHeight, setNavHeight] = useState(0);

    useEffect(() => {
        setHeaderHeight(getHeaderHeight());
        setNavHeight(getNavHeight());
        setIsMobile(size.width < 768);
    }, [children, location, size]);

    return (
        <div className="page-layout" style={{ ...(isMobile && { marginBottom: `${navHeight}px` }) }}>
            <div className="page-layout--nav">
                <NavigationSideBar headerHeight={ isMobile ? null : headerHeight } onUpdate={ () => window.setTimeout(() => { setNavHeight(getNavHeight()) }, 500) } />
            </div>
            <div className="page-layout--content">
                { children }
            </div>
            <div className="page-layout--footer">
                { footer }
            </div>
        </div>
    );
};

export default PageLayout;