import React, { createContext } from 'react';
import Cookies from 'js-cookie';
import getUserInfo from '../helpers/api/userInfo.js';

const UserContext = createContext();
export default UserContext;

const cookieName = "session";
 
/* Session is set server-side, so we can only read it here */
export const getSessionCookie = () => {
    const sessionCookie = Cookies.get(cookieName);
    if(typeof sessionCookie === "undefined") {
        return { authenticated: false, stayLocal: false };
    }
    else {
        try {
            return JSON.parse(sessionCookie);
        }
        catch (error) {
            console.error(error);
            return { authenticated: false, stayLocal: false };
        }
    }
};

/* Get user data */
export const loadUserInfo = async ({ logout }) => {
    try {
        return await getUserInfo({ logout });
    }
    catch (error) {
        console.error(`Getting user info failed, ${error}`);
    }
    return {};
};
