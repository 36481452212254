import React, { useState, useEffect } from 'react';
import PlayPause from './PlayPause.js';
import Volume from './Volume.js';

import './assets/player.css';

const AudioPlayerUI = ({ audioPlayer, play = { isPlaying: false, setIsPlaying: () => {} }, playerOptions = {}, config = {}, setState = () => {}, setTime = () => {} }) => {
  // const [isPlaying, setIsPlaying] = useState(setPlaying);
  const [trackProgress, setTrackProgress] = useState(0);

  // Destructure for conciseness
  const { duration } = audioPlayer;
  const validDuration = typeof duration === "number" && duration > 0;

  const currentPercentage = validDuration ? (trackProgress / duration) : 0;
  const trackStyling = `linear-gradient(90deg, #cbd1d8 ${currentPercentage * 100}%, #dadee3 ${currentPercentage * 100}%)`;
  const remainingSecods = validDuration ? duration - (duration * currentPercentage) : 0;
  const remainingTime = new Date(remainingSecods * 1000).toISOString().substring(11, 19).replace(/^0+:/, "");

  const startPlaying = () => !play.isPlaying && play.setIsPlaying(true);

  /* Tick every second to move progress bar/timer label */
  useEffect(() => {
    const interval = window.setInterval(() => {
      setTrackProgress(audioPlayer.currentTime);
    }, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    typeof playerOptions.currentTime !== "undefined" && setTrackProgress(playerOptions.currentTime);
  }, [playerOptions]);

  return (
    <div className={`themed-player themed-player--audio ${config.solidColor ? `themed-player--color-${config.solidColor}` : ""} ${config.removeUpperRounding ? "themed-player--no-upper-rounding" : ""} ${play.isPlaying ? "themed-player--playing" : "themed-player--stopped"}`}>
      <div className="themed-player--color-backdrop"></div>
      { config.image && (
        <div className="themed-player--image-wrapper" onClick={() => play.setIsPlaying(!play.isPlaying)}>
          <div className="themed-player--image" style={{ backgroundImage: `url('${config.image}')` }}></div>
        </div>
      ) }
      <div className="themed-player--layout">
        <div className="themed-player--controls">
          <PlayPause isPlaying={play.isPlaying} setIsPlaying={play.setIsPlaying} />
        </div>
        <div className="themed-player--controls">
          <div className="themed-player--range-wrapper">
            <input
                type="range"
                value={ trackProgress }
                step="1"
                min="0"
                max={ validDuration ? duration : 0 }
                className="themed-player--progress"
                onChange={ event => setTime(event.target.value) }
                onMouseUp={ startPlaying }
                onKeyUp={ startPlaying }
                style={{ background: trackStyling }}
            />
            <div className="themed-player--button-tooltip">{ new Date(trackProgress * 1000).toISOString().substring(11, 19).replace(/^0+:/, "") }</div>
          </div>
        </div>
        <div className="themed-player--controls">
          <span className="themed-player--time-remaining">{remainingTime}</span>
        </div>
        <div className="themed-player--controls">
          <Volume
            muted={ playerOptions.muted }
            volume={ typeof playerOptions.volume !== "undefined" ? playerOptions.volume : 1 }
            onMuteChange={ (mute) => setState({ muted: mute }) }
            onVolumeChange={ (volume) => setState({ volume }) }
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayerUI;
