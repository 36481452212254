import React, { useEffect, useState, useContext, memo } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import GlobalContext from "../../contexts/global.js";
import Collection from '../../components/Collection.js';
import HeroSection from '../../components/HeroSection.js';
import SiteQuote from '../../components/SiteQuote.js';
import FeaturedContent from '../../components/FeaturedContent.js';
import Loader from '../../components/Loader.js';
import { changeBrowserTitle } from '../../hooks/headAmender.js';
import { getTopicConfig } from '../../helpers/api/topicConfig.js';

const TopicHeroSection = memo(function topicHeroSection({ categoryKey, categoryValue }) {
    return (
        <HeroSection heading={categoryValue} loadImage={async ({ logout }) => {
            const config = await getTopicConfig({ logout, category: categoryKey });
            return {
                image: config.options?.heroImage,
                textColor: config.options?.heroTextColor,
                format: true
            };
        }} />
    );
});

const Topic = ({ prefix }) => {
    const { globalContext } = useContext(GlobalContext);
    const location = useLocation();
    const { categoryValue } = useParams(); /* location.pathname.replace(new RegExp(`^${prefix}/*`, ""), ""); */

    const [category, setCategory] = useState(categoryValue);
    const [incorrectTopic, setIncorrectTopic] = useState(false);
    const [displayFeaturedContent, setDisplayFeaturedContent] = useState(true);

    useEffect(() => {
        if(categoryValue) {
            const matchingCategory = (globalContext?.categories || []).find(category => category.key === categoryValue);
            if(matchingCategory) {
                setCategory(matchingCategory);
                setIncorrectTopic(false);
            }
            else if((globalContext?.categories || []).length) {
                setIncorrectTopic(true);
            }
        }
    }, [categoryValue, globalContext?.categories]);

    /* Set browser title */
    category?.value && changeBrowserTitle(`${category.value}${globalContext?.siteConfig?.siteName ? ` - ${globalContext.siteConfig.siteName}` : ""}`);

    if(incorrectTopic) {
        return (
            <section>
                <div className="container">
                    <h1>This page doesn&apos;t exist</h1>
                    <Link to="/" className="collection--load-more" style={{ float: "left" }}>Go to the homepage</Link>
                </div>
            </section>
        );
    }

    const prominentCollection = location.search.match(/(\?|&)page(=|$)/);
    if(prominentCollection) {
        return (
            <section>
                <div className="container">
                    <h1>{category?.value}</h1>
                    <Collection
                        filter={{
                            type: "category",
                            value: category?.ignoreFilter ? null : category?.key
                        }}
                        showCount={true}
                        paginateAfter={12}
                    />
                </div>
            </section>
        );
    }

    return (
        <>
            <TopicHeroSection categoryKey={category?.key} categoryValue={category?.value} />

            { category?.key && (
                <section style={{ ...(!displayFeaturedContent && { display: "none" }) }}>
                    <div className="container">
                        <FeaturedContent
                            heading="Featured content"
                            category={ category?.key }
                            onPagesChanged={ pages => setDisplayFeaturedContent(pages.length ? true : false) }
                        />
                    </div>
                </section>
            )}

            <section>
                <div className="container">
                    <Loader running={!category?.key}/>
                    { category?.key && (
                        <Collection
                            filter={{
                                type: "category",
                                value: category?.ignoreFilter ? null : category?.key
                            }}
                            heading="Recent posts"
                            showCount={false}
                            paginateAfter={12}
                            pagination={false}
                            fullWidth={true}
                            customTools={
                                <Link to={`${location.pathname}?page`} className="collection--load-more" style={{ float: "right" }}>Older items</Link>
                            }
                        />
                    )}

                    <SiteQuote />
                </div>
            </section>
        </>
    );
};

export default Topic;