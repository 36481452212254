import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import useGlobal from '../../hooks/useGlobal.js';
import GlobalContext from '../../contexts/global.js';
import Collection from '../../components/Collection.js';
import FeaturedContent from '../../components/FeaturedContent.js';
import FeaturedContact from '../../components/FeaturedContact.js';
import { changeBrowserTitle } from '../../hooks/headAmender.js';
import InstagramFeed from '../../components/Plugins/instagram/InstagramFeed.js';

import '../../styles/homePage.css';

const Home = ({ prefix }) => {
    const { getCategoryOption } = useGlobal();
    const { globalContext } = useContext(GlobalContext);

    const [hpConfig, setHpConfig] = useState({});

    /* Set browser title */
    changeBrowserTitle(globalContext?.siteConfig?.siteName || "Homepage");
    
    useEffect(() => {
        let isMounted = true;
        (async () => {
            const data = await getCategoryOption({ category: "homepage" }) ?? {};
            isMounted && data && setHpConfig(data);
        })();
        return () => {
            isMounted = false;
        }
    }, []);

    return (
        <>
            <section className="home-page">
                <div className="container">
                    <FeaturedContent
                        heading={ hpConfig.heroTitle ?? "Featured" }
                        type={ hpConfig.heroStyle ?? null }
                        videoSrc={ hpConfig.heroVideo ?? "" }
                        colorStyle={ hpConfig.heroColors ?? "" }
                    />
                </div>
            </section>

            { hpConfig.featuredContact && (
                <section className="home-page">
                    <div className="container">
                        <FeaturedContact
                            title="Focus on our people"
                            contact={ hpConfig.featuredContact ?? "" }
                            quote={ hpConfig.featuredContactQuote ?? "" }
                        />
                    </div>
                </section>
            )}

            <section className="home-page">
                <div className="container">
                    <div className="grid grid--md-66-33">
                        <div>
                            <Collection
                                prefix={prefix}
                                heading="Latest news"
                                half={true}
                                paginateAfter={12}
                                pagination={false}
                                filter={{
                                    type: "category",
                                    value: null
                                }}
                                customTools={
                                    <Link to="topic/all" className="collection--load-more">See all articles</Link>
                                }
                                toolsAlignment="center"
                            />
                        </div>
                        <div>
                            <div>
                                <InstagramFeed count={10} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;