import React, { useContext } from 'react';
import UserContext from '../contexts/user.js';

import '../styles/footer.css';

const Footer = () => {
    // const { userContext } = useContext(UserContext);

    const mainLinks = [
        { href: "https://www.pwc.com/gx/en/about/office-locations.html", label: "PwC office locations", target: "" },
        { href: "https://www.pwc.com/gx/en/site-map.html", label: "Site map", target: "" },
        { href: "https://www.pwc.com/gx/en/global/forms/contactUs.en_gx.html?parentPagePath=/content/pwc/gx/en&style=", label: "Contact us", target: "" }
    ];

    const diclimerLinks = [
        { href: "https://www.pwc.com/gx/en/legal-notices/pwc-privacy-statement.html", label: "Privacy statement", target: "" },
        { href: "https://www.pwc.com/gx/en/site-information/legal-disclaimer.html", label: "Legal disclaimer", target: "" },
        { href: "https://www.pwc.com/m1/en/cookie-information.html", label: "Cookies information", target: "" },
        { href: "https://www.pwc.com/m1/en/home.html", label: "Site provider", target: "" }
    ];

    return (
        <section className="footer section--no-padding">
            <div className="footer--content">
                <ul className="footer--main-links">
                    {
                        mainLinks.map((link, index) => <li key={index}><a href={link.href} target={link.target}>{link.label}</a></li>)
                    }
                </ul>
                <div className="footer--disclaimer">
                    <p>© 2011-{new Date().getFullYear()} PwC. All rights reserved. PwC refers to the PwC network
                        and/or one or more of its member firms, each of which is a separate
                        legal entity.<br/>Please see <a href="https://www.pwc.com/structure">www.pwc.com/structure</a> for further details.
                    </p>
                </div>
                <ul className="footer--disclaimer-links">
                    {
                        diclimerLinks.map((link, index) => <li key={index}><a href={link.href} target={link.target}>{link.label}</a></li>)
                    }
                </ul>
            </div>
        </section>
    );
};

export default Footer;