import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

export const getSnippetByUrn = async ({ type, urn, logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/live/snippet/detail/${type}/${urn}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            const parsed = await response.json();
            if(parsed.snippet) {
                return parsed.snippet;
            }
            return null;
        }
        catch (error) {
            throw new Error(`Failed lading snippet content: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load snippet content: ${response.statusText}`);
};

export const getCount = async ({ type, logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/live/snippet/count/${type}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing the count of snippets with details: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load the count of snippets with details: ${response.statusText}`);
};

export const list = async ({ type, skip = 0, limit = 1, order = "asc", sort = null, searchKeys = null, search = "", logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/live/snippet/list/${type}?skip=${skip}&limit=${limit}&order=${order}` + (sort ? `&sort=${sort}` : "") + ((Array.isArray(searchKeys) && searchKeys.length && search) ? `&searchKeys=${JSON.stringify(searchKeys)}&search=${search}` : ""), {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing the list of snippets with details: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load the list of snippets with details: ${response.statusText}`);
};


export const getSnippetPreviewContentByIndex = async ({ id }) => {
    const response = await fetch(`${apiEndPoint}/api/author/snippet/${id}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            const parsed = await response.json();
            if(parsed.snippet) {
                return parsed.snippet;
            }
            return null;
        }
        catch (error) {
            throw new Error(`Failed lading snippet content: ${error}`);
        }
    }
    throw new Error(`Could not load snippet content: ${response.statusText}`);
};