import React, { useEffect, useContext } from 'react';
import useUser from '../hooks/useUser.js';
import UserContext, { loadUserInfo } from '../contexts/user.js';

const UserConfig = () => {
    const { userContext, setUserContext } = useContext(UserContext);
    const { logout } = useUser();

    useEffect(() => {
        let isMounted = true;
        userContext.authenticated && (async () => {
            const response = await loadUserInfo({ logout });
            if(isMounted && response) {
                setUserContext({
                    ...userContext,
                    user: response
                });
            }
        })();
        return () => {
          isMounted = false;
        };
      }, [userContext.authenticated]);
    

    return null;
};

export default UserConfig;
