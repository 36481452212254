import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

export const getTopicConfig = async ({ logout = logoutPlaceholder, category = "all" }) => {
    const response = await fetch(`${apiEndPoint}/api/live/topicConfig/${category}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            const data = await response.json();
            return data.config || {};
        }
        catch (error) {
            throw new Error(`Failed parsing topic config: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load topic config: ${response.statusText}`);
};
