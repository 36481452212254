import React from 'react';

import '../styles/loader.css';

const Loader = ({ running = false, text = "Loading ...", absolute = false }) => {
    return (
        <div className={`loader ${running ? "running" : ""} ${absolute ? "absolute" : ""}`}>
            <div className="animation">
                <div></div>
                <div></div>
            </div>
            <span className="info">{text}</span>
        </div> 
    );
};

export default Loader;