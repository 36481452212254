/* Extract prefered url login path */
export const preferredLoginMethodPath = options => Object.values(options || {}).find(({ preferred }) => preferred)?.login;

/* Compose login url */
export const getLoginEntryPoint = ({ loginPath, location }) => {
    return`${process.env.REACT_APP_FETCH_URL}${loginPath}?origin=${encodeURI(`${process.env.PUBLIC_URL}${location?.state?.referer || location?.pathname || "/"}`)}`;
};

/* use local login? */
export const useLocalLogin = (location) => location.search.match(/(\?|&)local/);

export const requestLogin = ({ location, navigate, availableLoginOptions, preferredLoginOption }) => {
    const availableEntries = availableLoginOptions().entry;
    const type = (useLocalLogin(location) && availableEntries.direct) ? availableEntries.direct :
    (Object.values(preferredLoginOption).length ? Object.values(preferredLoginOption)[0] : null);

    if(type) {
        if(type.method === "redirect") {
            window.location.href = getLoginEntryPoint({ loginPath: type.login, location });
        }
        else {
            /* Getting errors without artificial timeout with live react dev environment, why? :'( */
            /* Warning: Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state. */
            window.setTimeout(() => {
                navigate("/login",
                {
                    state: { referer: location.pathname + location.search }
                });
            }, 100);
        }
    }
};
