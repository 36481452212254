import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

export const verifyInstagramFeed = async ({ logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/plugins/instagramFeedVerify`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing the instagram feed verification: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load the instagram feed verification: ${response.statusText}`);
};

export const getInstagramFeed = async ({ count = 3, logout = logoutPlaceholder }) => {
    const response = await fetch(`${apiEndPoint}/api/plugins/instagramFeed?count=${count}`, {
        mode: "cors",
        credentials: "include"
    });
    if(response.ok) {
        try {
            return await response.json();
        }
        catch (error) {
            throw new Error(`Failed parsing the instagram feed: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not load the instagram feed: ${response.statusText}`);
};
