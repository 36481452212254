import logoutPlaceholder from '../../hooks/logoutPlaceholder.js';

const apiEndPoint = process.env.REACT_APP_FETCH_URL;

export const getLocalFileUrl = ({ path = "" }) => {
    return `${apiEndPoint}/api/live/files/getFile/${path.replace(/^\//, "")}`;
};

export const getFile = async ({ path, logout = logoutPlaceholder }) => {
    const response = await fetch(getLocalFileUrl({ path }), {
        mode: "cors",
        credentials: "include",
        method: "GET"
    });
    if(response.ok) {
        try {
            return response;
        }
        catch (error) {
            throw new Error(`Failed fetching file ${path}: ${error}`);
        }
    }
    response.status === 401 && logout();
    throw new Error(`Could not get the file ${path}: ${response.statusText}`);
};
