import React, { useState, useEffect } from 'react';
import useUser from '../hooks/useUser.js';
import formatAssetUrl from '../helpers/formatAssetUrl.js';

import '../styles/heroSection.css';

const HeroSection = ({ heading = null, textColor = null, backgroundColor = "default", image = "",  beforeLoadPlaceholderImage = true, preventPlaceholderImage = false, loadImage }) => {
    const { logout } = useUser();
    const [loading, setLoading] = useState(false);
    const [heroImage, setHeroImage] = useState("");
    const [heroTextColor, setHeroTextColor] = useState(textColor);

    useEffect(() => {
        setHeroTextColor(textColor);
    }, [textColor]);

    useEffect(() => {
        let isMounted = true;
        const dynamicImage = typeof loadImage === "function";

        if(dynamicImage) {
            setLoading(true);
            (async () => {
                try {
                    const { image: dynamicImage = image, format = false, textColor = null } = await loadImage({ logout });
                    if(isMounted) {
                        if(dynamicImage && isMounted) {
                            if(format) {
                                setHeroImage(formatAssetUrl({
                                    path: dynamicImage,
                                    fallback: `${process.env.PUBLIC_URL}/assets/images/hero-placeholder.jpg`
                                }));
                            }
                            else {
                                setHeroImage(dynamicImage);
                            }
                        }
                        setHeroTextColor(textColor);
                    }
                }
                catch (error) {
                    console.error(error);
                }
                isMounted && setLoading(false);
            })();
        }

        setHeroImage(formatAssetUrl({
            path: image,
            fallback: (!preventPlaceholderImage && (!dynamicImage || beforeLoadPlaceholderImage)) ? `${process.env.PUBLIC_URL}/assets/images/hero-placeholder.jpg` : null
        }));
        
        return () => {
            isMounted = false;
        }
    }, [image, loadImage]);

    return (
        <section className={`hero-section ${backgroundColor ? `hero-section--background-${backgroundColor}` : ""}`}>
            <div className="container">
                <div className={`hero-section--content ${heroTextColor ? `hero-section--text-${heroTextColor}` : ""}`}>
                    { !loading && heroImage && <img className="hero-section--hero-image" src={heroImage} alt="background image" /> }
                    <h1 className="hero-section--heading">{heading}</h1>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;