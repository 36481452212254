import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser.js';
import GlobalContext from '../../contexts/global.js';
import { changeBrowserTitle } from '../../hooks/headAmender.js';
import { getSnippetPreviewContentByIndex } from '../../helpers/api/getSnippet.js';
import { getSnippetByUrn } from '../../helpers/api/getSnippet.js';
import PageNotFound from './PageNotFound.js';
import PodcastContent from '../../components/PodcastContent.js';

const Podcast = ({ prefix, preview = false }) => {
    const { globalContext } = useContext(GlobalContext);
    const { logout } = useUser();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [podcastData, setPodcastData] = useState({});

    /* Set browser title */
    const setTitle = (podcastName) => {
        changeBrowserTitle(`${podcastName}${(globalContext?.siteConfig?.siteName && podcastName) ? ` - ${globalContext.siteConfig.siteName}` : ""}`);
    }
    setTitle("");

    useEffect(() => {
        const urn = location.pathname.replace(new RegExp(`^${prefix}/?`), "");
        if(urn) {
            let current = true;
            (async () => {
                setLoading(true);
                const response = preview ? await getSnippetPreviewContentByIndex({ id: urn }) : await getSnippetByUrn({ type: "podcast", urn, logout });
                current && setPodcastData(response) && setLoading(false);

                /* Set browser title */
                current && response.title && setTitle(response.title);
            })();
            return () => {
                current = false;
            }
        }
    }, []);

    if(!Object.keys(podcastData).length) {
        return <PageNotFound/>;
    }

    return (
        <>
            { preview && (
                 <section className="podcast">
                    <div className="container">
                        <p className="podcast--preview-notice">
                            <strong>You are currently in a preview mode.</strong>
                        </p>
                    </div>
                </section>
            ) }

            <section className="podcast">
                <div className="container">
                    <PodcastContent data={{ podcast: podcastData }} headingLevel="h2" />
                </div>
            </section>
        </>
    );
};

export default Podcast;