import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from '../hooks/useUser.js';
import AccessContext from '../contexts/access.js';
import UserContext from '../contexts/user.js';
import { requestLogin } from '../hooks/userAccess.js';

const RequireAuth = ({ children, ...rest }) => {
    const { accessContext } = useContext(AccessContext);
    const { availableLoginOptions, preferredLoginOption } = useUser(accessContext);
    const { userContext } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    if(!userContext.authenticated) {
        requestLogin({
            location,
            navigate,
            availableLoginOptions,
            preferredLoginOption
        });
        return null;
    }
    
    return (
        <div {...rest}>
            { children }
        </div>
    );
};

export default RequireAuth;
