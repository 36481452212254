import React, { useEffect, useState, useContext } from 'react';
import UserContext from "../contexts/user.js";
import useUser from '../hooks/useUser.js';
import { getUserData } from '../helpers/api/getUserData.js';
import { changeBrowserTitle } from '../hooks/headAmender.js';

const UserPage = () => {
    const { userContext } = useContext(UserContext);
    const { logout } = useUser();

    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const loadUserData = async () => {
            try {
                const data = await getUserData({ logout });
                isMounted && setUserData(data);
            }
            catch (error) {
                console.error(error);
            }
            isMounted && setLoading(false);
        };
        loadUserData();
        return () => isMounted = false;
    }, []);

    changeBrowserTitle("Your account");

    return (
        <section>
            <div className="container">
                <h1>Hello {userContext.user?.name ?? userContext.user?.email?.replace(/@.*/, "")}</h1>
                <p>Find your user account details below:</p>
                { loading ? "Loading ..." : (
                    <ul style={{ listStyleType: "none", padding: "20px", background: "#efefef" }}>
                        <li>email: {userContext.user?.email}</li>
                        { userContext.user?.id && (
                            <li>user id: &quot;{userContext.user?.id}&quot;</li>
                        )}
                    </ul>
                )}
            </div>
        </section>
    );
};

export default UserPage;
