import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import UserContext from "../contexts/user.js";
import GlobalContext from '../contexts/global.js';
import useGlobal from '../hooks/useGlobal.js';
import useUser from '../hooks/useUser.js';
import { fillPageHeadMeta } from '../hooks/headAmender.js';

import '../styles/header.css';

const Header = () => {
    const { userContext } = useContext(UserContext);
    const { globalContext } = useContext(GlobalContext);
    const { getCategoryOption } = useGlobal();
    const { logout } = useUser();
    const navigate = useNavigate();
    const isMounted = useRef(false);

    const [shortUrl, setShortUrl] = useState(null);
    const [searchContent, setSearchContent] = useState("");
    const [menuExpanded, setMenuExpanded] = useState(null);
    const [featuedBanner, setFeaturedBanner] = useState({});

    const getLocationShortUrl = async () => {
        const response = await fetch("https://api-ssl.bitly.com/v4/shorten", {
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer ae580fea9be50d6021023d5b41bfcad131dfdd68"
            },
            method: "POST",
            body: JSON.stringify({
                long_url: window.location.href
            })
        });
        if(response.ok) {
            try {
                const data = await response.json();
                return data.link || window.location.href;
            }
            catch (error) {
                console.error(error);
            }
        }
        return window.location.href;
    };

    const copyToClipboard = (content) => {
        // Or can use navigator.clipboard instead? Are we always going to work with https or on loaclhost?
        const inputGhost = document.createElement("input");
        document.body.appendChild(inputGhost);
        inputGhost.value = content;
        inputGhost.select();
        document.execCommand("copy");
        inputGhost.remove();
    };

    fillPageHeadMeta({
        name: globalContext?.siteConfig?.siteName,
        description: globalContext?.siteConfig?.siteDescription,
        owner: globalContext?.siteConfig?.siteOwner
    });

    useEffect(() => {
        isMounted.current = true;
        (async () => {
            const data = await getCategoryOption({ category: "siteConfig" });
            isMounted.current && data && setFeaturedBanner(data);
        })();
        return () =>  isMounted.current = false;
    }, []);

    useEffect(() => {
        document.querySelectorAll(".site-header .site-header--tool-expand").forEach(expandableSection => {
            if(expandableSection.dataset?.name === menuExpanded) {
                expandableSection.classList.add("site-header--tool-expand-fullview");
                const finalWidth = expandableSection.offsetWidth || 0;
                expandableSection.classList.remove("site-header--tool-expand-fullview");
                expandableSection.style.width = "0px";
                expandableSection.classList.add("site-header--tool-expand-open");
                expandableSection.querySelector("input[type='search']")?.focus();
                window.setTimeout(() => {
                    expandableSection.style.width = `${finalWidth}px`;
                }, 200);
                return;
            }
            expandableSection.style.width = null;
            expandableSection.classList.remove("site-header--tool-expand-open");
        });
    }, [menuExpanded]);

    return (
        <div className={`site-header ${ featuedBanner.featuredBannerLink ? "site-header--has-banner" : "" }`}>
            <div className="site-header--content">
                <div className="site-header--brand">
                    <Link className="site-header--logo" to="/" tabIndex="-1">
                        <img alt="PwC" src={`${process.env.PUBLIC_URL}/assets/logos/pwclogo.svg`} className="img-responsive"/>
                    </Link>
                    { globalContext?.siteConfig?.siteName && (
                        <>
                            <div className="site-header--divider"></div>
                            <Link className="site-header--site-name" to="/">{ globalContext?.siteConfig?.siteName }</Link>
                        </>
                    )}
                </div>

                <div className="site-header--tools">
                    <div className="site-header--tool site-header--tool-user">
                        <div className="site-header--tool-button" tabIndex="4" onClick={ () => setMenuExpanded( menuExpanded === "user" ? null : "user") }>
                            <div className="site-header--tool-icon">
                                <div className="site-header--tool-icon-user"></div>
                                <div className="site-header--tool-icon-user site-header--tool-icon-active"></div>
                            </div>
                        </div>
                        { userContext.authenticated ? (
                            <>
                                <span>Welcome <Link to="/user">{userContext.user?.name ?? userContext.user?.email?.replace(/@.*/, "")}</Link></span>
                                <div className="site-header--tool-expand" data-name="user">
                                    <div className="site-header--tool-padded-content">
                                        <Link to="#" onClick={logout}>Log out</Link>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="site-header--tool-padded-content">
                                <Link to="/login">Login</Link>
                            </div>
                        )}
                    </div>
                </div>

                <div className="site-header--tools">
                    <div className="site-header--tool site-header--tool-search">
                        <div className="site-header--tool-button" tabIndex="2" onClick={ () => setMenuExpanded( menuExpanded === "search" ? null : "search") }>
                            <div className="site-header--tool-icon">
                                <div className="site-header--tool-icon-search"></div>
                                <div className="site-header--tool-icon-search site-header--tool-icon-active"></div>
                            </div>
                        </div>
                        <div className="site-header--tool-expand" data-name="search">
                            <form className="site-header--tool-padded-content" onSubmit={(event) => {
                                event.preventDefault();
                                navigate(`/search/${searchContent}`);
                            }}>
                                <input
                                    type="search"
                                    className="site-header--tool-search-box"
                                    autoComplete="off"
                                    placeholder="Search"
                                    value={searchContent}
                                    onChange={(event) => setSearchContent(event.currentTarget.value)}
                                />
                            </form>
                        </div>
                    </div>

                    <div className="site-header--tool site-header--tool-share">
                        <div
                            className="site-header--tool-button"
                            tabIndex="3"
                            onClick={async (event) => {
                                const short = shortUrl || await getLocationShortUrl();
                                if(isMounted.current) {
                                    setShortUrl(short);
                                    copyToClipboard(short);

                                    const popover = document.querySelector(".site-header--popover");
                                    if(popover) {
                                        popover.classList.add("site-header--popover-visible");
                                        window.setTimeout(() => {
                                            popover.classList.remove("site-header--popover-visible");
                                        }, 3000);
                                    }
                                }
                            }}
                        >
                            <div className="site-header--tool-icon">
                                <div className="site-header--tool-icon-share"></div>
                                <div className="site-header--tool-icon-share site-header--tool-icon-active"></div>
                            </div>
                        </div>
                        <div className="site-header--popover">
                            <div className="site-header--popover-arrow"></div>
                            <div className="site-header--popover-content">Copied link to clipboard</div>
                        </div>
                    </div>
                </div>
            </div>
            { featuedBanner.featuredBannerLink && (
                <a href={ featuedBanner.featuredBannerLink ?? "#" } tabIndex="4" className="site-header--banner">
                    <div className="site-header--banner-text">{ featuedBanner.featuredBannerText ?? "Find out more" }</div>
                </a>
            )}
        </div>
    );
};

export default Header;