import React, { useState, useEffect, useRef } from 'react';
import AudioPlayerUI from './AudioPlayerUI.js';

const Player = ({
  src,
  playerOptions: defaultPlayerOptions = { muted: false, autoPlay: true },
  config = { solidColor: null, removeUpperRounding: false, image: null }
}) => {
  const audioRef = useRef(new Audio(src));

  const [isPlaying, setIsPlaying] = useState(false);
  const [playerOptions, setPlayerOptions] = useState(defaultPlayerOptions);

  const setState = (options) => {
    setPlayerOptions({
      ...playerOptions,
      ...options
    });
  };

  useEffect(() => {
    /* Pause and clean up on unmount */
    return () => {
      audioRef.current.pause();
    };
  }, []);

  /* Reset on src change */
  useEffect(() => {
    setIsPlaying(false);
    audioRef.current.src = src;
    setPlayerOptions(defaultPlayerOptions);

    if(defaultPlayerOptions.autoPlay) {
      audioRef.current.onloadeddata = () => {
        setIsPlaying(true);
      };
    }
  }, [src]);

  useEffect(() => {
    Object.entries(playerOptions).filter(([key, value]) => value !== undefined && value !== null).forEach(([ key, value ]) => {
      audioRef.current[key] = value;
    });
  }, [playerOptions]);

  useEffect(() => {
    isPlaying ? audioRef.current.play() : audioRef.current.pause();
  }, [isPlaying]);

  return (
    <AudioPlayerUI
      audioPlayer = { audioRef.current }
      play = {{ isPlaying, setIsPlaying }}
      playerOptions = { playerOptions }
      config = { config }
      setState = { setState }
      setTime = { time => audioRef.current.currentTime = Number(time) }
    />
  );
};

export default Player;
